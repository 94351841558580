<template>
  <header class="header">
    <div class="container-fluid h-100">
      <div class="row h-100 align-items-center">
        <div class="col-auto header-logo__col">
          <div class="header-logo__container">
            <nuxt-link to="/"><img :src="logo" class="header-logo" /></nuxt-link>
          </div>
        </div>
        <div class="col">
          <div class="row justify-content-between">
            <div class="col mw-50 header-info__container">
              <div class="header-info hide-1500" v-if="info">{{info}}</div>
            </div>
            <div class="col-auto">
              <div class="header-controls d-flex justify-content-end align-items-center h-100">
                <div class="header-geoposition">
                  <button class="header-geoposition__button" type="button">
                    <div class="header-time">{{localTime(currentCity.timezone)}}</div>
                    <div class="header-location d-none d-sm-block" :title="currentCity.city">
                      {{currentCity.city}}
                    </div>
                  </button>
                  <Select
                      v-model="currentCity"
                      :options="citiesData"
                      class="header-location__dropdown"
                      appendTo="self"
                      @change="updateCity"
                  >
                    <template #value="slotProps">
                      <span v-if="slotProps.value.city">
                        {{ slotProps.value.city }}
                      </span>
                      <span v-else>
                        {{ slotProps.placeholder }}
                    </span>
                    </template>
                    <template #option="slotProps">
                      <span class="p-select-option-label">
                        {{slotProps.option.city}} (GMT {{slotProps.option.gmt}})
                      </span>
                    </template>
                  </Select>
                </div>
                <template v-if="user.init">
                  <nuxt-link to="/cabinet" class="header-profile d-none d-md-inline-flex" @click="hideMenu">
                    <img :src="cabinet" alt="Кабинет" />
                    <div>
                      {{user.userInfo.firstname}} <br class="d-1600-none hide-1500 show-992">{{user.userInfo.lastname}}
                      <template v-if="!user.isConfirmed">
                        <br>
                        <span>Не подтвержден</span>
                      </template>
                    </div>
                  </nuxt-link>
                  <div class="header-profile d-md-none">
                    <button class="header-profile__icon" type="button">
                      <img :src="cabinet" alt="Кабинет" />
                    </button>
                    <div class="header-profile__popup">
                      <img :src="cabinetInvert" alt="Кабинет" />
                      <div>
                        {{user.userInfo.firstname}} {{user.userInfo.lastname}}
                        <template v-if="!user.isConfirmed">
                          <br>
                          <span>Не подтвержден</span>
                        </template>
                      </div>
                      <nuxt-link to="/cabinet" class="button" @click="hideMenu">Личный кабинет</nuxt-link>
                      <button
                          class="button button--transparent --logout mx-auto"
                          @click="logout"
                          type="button"
                      >Выйти</button>
                    </div>
                  </div>
                  <button class="button button--transparent --mail" @click="$router.push('contacts')" type="button"></button>
                  <button
                      class="button button--transparent --logout d-none d-md-inline-flex"
                      @click="logout"
                      type="button"
                  ><span class="d-none d-xl-inline">Выйти</span></button>
                </template>
                <nuxt-link
                    v-else
                    class="button button--transparent --logout ms-0"
                    to="/login"
                >Войти</nuxt-link>
                <button
                    class="header-burger d-xl-none"
                    @click="toggleMenu"
                    type="button"
                ><span></span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="header-menu-mobile d-xl-none">
    <Sidemenu />
  </div>
</template>

<script setup lang="ts">
  import logo from "~/components/layout/img/logo.svg";
  import cabinet from "~/components/layout/img/header/icon-profile.svg";
  import cabinetInvert from "~/components/layout/img/header/icon-profile-invert.svg";
  import Select from 'primevue/select';

  import { ref, onBeforeUnmount, onMounted } from 'vue';
  import Sidemenu from "~/components/layout/Sidemenu.vue";

  import {ApiRequest} from "~/utils/api-request";
  import {loadUserInfo, user} from "~/utils/user";
  import cookie from "~/utils/cookie";

  const router = useRouter();

  const props = defineProps({
    info: {
      type: String,
      default: '',
    }
  });

  let errors = ref({
    message: null!,
    errors: {

    }
  });

  let currentCity = ref({});
  let citiesData = ref([
    {city: 'Калининград', gmt: "+2", timezone: 'Europe/Kaliningrad'},
    {city: 'Москва', gmt: "+3", timezone: 'Europe/Moscow'},
    {city: 'Самара', gmt: "+4", timezone: 'Europe/Samara'},
    {city: 'Екатеринбург', gmt: "+5", timezone: 'Asia/Yekaterinburg'},
    {city: 'Омск', gmt: "+6", timezone: 'Asia/Omsk'},
    {city: 'Красноярск', gmt: "+7", timezone: 'Asia/Krasnoyarsk'},
    {city: 'Иркутск', gmt: "+8", timezone: 'Asia/Irkutsk'},
    {city: 'Якутск', gmt: "+9", timezone: 'Asia/Yakutsk'},
    {city: 'Владивосток', gmt: "+10", timezone: 'Asia/Vladivostok'},
    {city: 'Магадан', gmt: "+11", timezone: 'Asia/Magadan'},
    {city: 'Петропавловск-Камчатский', gmt: "+12", timezone: 'Asia/Kamchatka'},
  ]);
  currentCity.value = citiesData.value[1];

  const currentDate = ref(new Date());
  const updateCurrentTime = () => {
    currentDate.value = new Date();
  };
  const updateTimeInterval = setInterval(updateCurrentTime, 1000);
  const localTime = (tz) => {
    const options = { timeStyle: 'short', timeZone: tz };
    const formatter = new Intl.DateTimeFormat('ru-RU', options);
    return formatter.format(currentDate.value);
  }

  onBeforeUnmount(() => {
    clearInterval(updateTimeInterval);
  });

  const logout = async() => {
    let apiRequest = new ApiRequest('post', 'auth/logout', null);

    apiRequest.onSuccess((data) => {

      cookie.authToken.setValue(null);

      // console.log(data);
      window.location.assign("/login");
      // router.push({ path: "/login" });
    });

    apiRequest.onValidationError((error) => {
      errors.value = error;
    });
    apiRequest.onError((error) => {
      errors.value = error;
    });

    apiRequest.onServerError((error) => {
      errors.value.message = 'ошибка сервера 2 (500>)';
    });

    let data = await apiRequest.request();
    // console.log(data);
  }

  const updateCity = async() => {

    if (!user.init) {
      return;
    }

    let apiRequest = new ApiRequest('post', 'user/personal/timezone/update', null,{
      timezone: currentCity.value.gmt,
    });

    apiRequest.onSuccess((data) => {
      console.log(data);
    });

    apiRequest.onError((error) => {
      // errors
    });

    let data = await apiRequest.request();
    // console.log(data);
  }

  const toggleMenu = () => {
    document.body.classList.toggle('menu-expanded');
  }
  const hideMenu = () => {
    document.body.classList.remove('menu-expanded');
  }
  onMounted(async () => {

      await loadUserInfo();

      if (user.init) {
        let city = Object.values(citiesData.value).find(o => o.city === user.userInfo.timezone.city);
        let cityIndex = citiesData.value.indexOf(city);
        if (cityIndex < 0){
          city = Object.values(citiesData.value).find(o => o.gmt === user.userInfo.timezone.timezone);
          cityIndex = citiesData.value.indexOf(city);
        }
        cityIndex = (cityIndex < 0) ? 1 : cityIndex;
        currentCity.value = citiesData.value[cityIndex];
      }

  });
</script>

<style>
  .header-location__dropdown .p-select-label,
  .header-location__dropdown .p-select-dropdown{
    display: none !important;
  }
</style>
<style scoped>
  .header{
    height: 4.8rem;
    border-radius: 0 0 15px 15px;
    background: #fff;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    box-shadow: 0 0 10px 0 #E6E7E7;
    z-index: 99;
  }
  .header-logo__container{
    width: 286px;
  }
  .header-logo{
    width: 10rem;
    height: auto;
  }
  .header-controls .button--transparent{
    position: relative;
    padding: 6px 7px;
  }
  .header-controls .button.--logout{
    margin-left: 16px;
  }
  .header-profile{
    font-size: 14px;
    margin-right: 1.6rem;
    min-height: 30px;
    display: inline-flex;
    align-items: center;
    position: relative;
  }
  .header-profile img{
    width: 30px;
    height: 30px;
  }
  .header-profile > div{
    margin-left: 8px;
  }
  .header-profile span{
    color: #14BEAA;
    font-size: 12px;
  }
  .header-profile__popup{
    width: 293px;
    border-radius: 5px;
    background: #4B4B4B;
    padding: 16px;
    font-size: 14px;
    color: #E6E7E7;
    text-align: center;
    position: absolute;
    right: -24px;
    top: 100%;
    z-index: 1;
    margin-top: 14px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ease 0.3s, opacity 0.3s ease 0s;
  }
  .header-profile__popup:before{
    content: '';
    border: 8px solid transparent;
    border-top: none;
    border-bottom: 14px solid #4B4B4B;
    position: absolute;
    bottom: 100%;
    right: 30px;
    margin-bottom: -1px;
  }
  .header-profile__popup .button.--logout{
    color: #E6E7E7;
    border-color: #E6E7E7;
  }
  @media(hover: hover){
    .header-profile__popup .button.--logout:hover{
      background: rgba(30, 30, 28, 0.25) !important;
    }
  }
  .header-profile__popup .button.--logout:before{
    background-image: url('@/components/layout/img/header/icon-logout-invert.svg');
  }
  .header-profile__popup .button{
    margin-top: 16px;
  }
  .header-profile__popup img{
    margin-bottom: 8px;
  }
  .header-profile__icon{
    cursor: pointer;
  }
  .header-profile:hover .header-profile__popup{
    visibility: visible;
    opacity: 1;
    transition: visibility 0s ease 0s, opacity 0.3s ease 0s;
  }
  .header-geoposition{
    margin-right: 1.6rem;
    position: relative;
  }
  .header-geoposition__button{
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .header-location{
    position: relative;
    padding-right: 19px;
    font-size: 14px;
    line-height: 1.5;
    max-width: 144px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: url('../../assets/img/icon-dropdown.svg') right center / 11px 5px no-repeat;
  }
  .header-location__dropdown{
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 !important;
    border: none !important;
    background: transparent !important;
  }
  .header-time{
    width: 62px;
    margin-right: 4px;
    font-size: 14px;
    line-height: 1.5;
    padding-left: 25px;
    background: url('../../components/layout/img/sidemenu/icon-clock.svg') left center / 17px 18px no-repeat;
  }
  .header-burger{
    margin-left: 16px;
    width: 22px;
    height: 22px;
    position: relative;
    cursor: pointer;
  }
  .header-burger:before,
  .header-burger:after{
    content: ''
  }
  .header-burger:before,
  .header-burger:after,
  .header-burger span{
    width: 100%;
    height: 2px;
    margin-top: -1px;
    border-radius: 1px;
    background: #FC6337;
    position: absolute;
    left: 0;
    transition: all 0.3s ease;
  }
  .header-burger:before{top: 22.5%}
  .header-burger:after{top: 77.5%}
  .header-burger span{top: 50%}

  body.menu-expanded .header-burger span{
    opacity: 0;
  }
  body.menu-expanded .header-burger:before{
    transform: rotate(45deg);
    top: 50%;
  }
  body.menu-expanded .header-burger:after{
    transform: rotate(-45deg);
    top: 50%;
  }

  .header-menu-mobile{
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    width: 100%;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(7px);

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ease 0.5s, opacity 0.2s ease 0.3s;
  }
  body.menu-expanded .header-menu-mobile{
    visibility: visible;
    opacity: 1;
    transition: visibility 0s ease 0s, opacity 0.2s ease 0s;
  }
  .header-menu-mobile .sidemenu{
    border-radius: 0;
    border-top: 4.8rem solid #fff;
    transform: translate(-100%, 0);
    transition: all 0.3s ease 0s;
  }
  body.menu-expanded .header-menu-mobile .sidemenu{
    transform: translate(0%, 0);
    transition: all 0.3s ease 0.2s;
  }

  @media(max-width: 1499px){
    .hide-1500{
      display: none;
    }
    .header-info__container{
      padding-left: 0;
      padding-right: 0;
    }
    .header-logo__col{
      padding-right: 0;
    }
  }
  @media (max-width: 1199px){
    .header-logo__container{
      width: auto;
    }
    .header-menu-mobile{
      position: fixed;
    }
    .header-controls .button.--logout.d-none::before{
      margin-right: 0;
    }
  }
  @media (max-width: 991px){
    .header-logo{
      width: 9rem;
    }
    .show-992{
      display: inherit;
    }
    .header-geoposition,
    .header-profile{
      margin-right: 16px;
    }
  }
  @media(max-width: 767px){
    .header-logo{
      width: 9rem;
    }
  }
  @media(max-width: 575px){
    .header{
      height: 62px;
    }
    .header-menu-mobile .sidemenu{
      border-top-width: 62px;
      width: 100%
    }
    .header-geoposition__button{
      padding-right: 12px;
      background: url('@/assets/img/icon-dropdown.svg') right center / 11px 5px no-repeat;
    }
  }
</style>
