import iconAll from "~/components/layout/img/sidemenu/icon-all.svg";
import iconLink from "~/components/layout/img/sidemenu/icon-external.svg";
import iconDoc from "~/components/layout/img/sidemenu/icon-doc.svg";

import iconHome from "~/components/layout/img/sidemenu/icon-home.svg"
import iconHeart from "~/components/layout/img/sidemenu/icon-heart.svg"
import iconClock from "~/components/layout/img/sidemenu/icon-clock.svg"
import iconWait from "~/components/layout/img/sidemenu/icon-wait.svg"
import iconList from "~/components/layout/img/sidemenu/icon-list.svg"
import iconCheck from "~/components/layout/img/sidemenu/icon-check.svg"
import iconCalendar from "~/components/layout/img/sidemenu/icon-calendar.svg"
import iconHelp from "~/components/layout/img/sidemenu/icon-help.svg"
import iconContact from "~/components/layout/img/sidemenu/icon-contact.svg"
import iconInfo from "~/components/layout/img/sidemenu/icon-info.svg"

export default {
    iconAll,
    iconLink,
    iconDoc,
    iconHome,
    iconHeart,
    iconClock,
    iconWait,
    iconList,
    iconCheck,
    iconCalendar,
    iconHelp,
    iconContact,
    iconInfo,
}