<template>

  <div class="layout">
    <div class="layout_content">
      <main class="page">
        <Header :info="headerData.info" />
        <div class="page-layout">
          <div class="container-fluid h-100">
            <div class="row h-100">
              <div class="col-auto page-aside d-none d-xl-block">
                <Sidemenu />
              </div>
              <div class="col page-content" :class="{'--error': $slots.errors}">
                <div class="header-info mb-4 d-1500-none" v-if="headerData.info">{{headerData.info}}</div>
                <NuxtPage />
                <slot name="errors"/>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>

</template>

<script setup lang="ts">
  import Header from "~/components/layout/Header.vue";
  import Sidemenu from "~/components/layout/Sidemenu.vue";
  import auth from "~/utils/auth";

  import {ref, onMounted, onUnmounted} from "vue";
  import cookie from "~/utils/cookie";
  import {ApiRequest} from "~/utils/api-request";

  let headerData = ref({
    info: '',
  });

  let errors = ref({
    message: null!,
    errors: {

    }
  });

  const updateViewport = () => {
    let pageViewport = document.querySelector('head meta[name="viewport"]');
    let vpContent = (screen.width < 375) ? 'width=375' : 'width=device-width, initial-scale=1';
    // console.log(screen.width);
    pageViewport.setAttribute('content', vpContent);
  }

  const getBannerContent = async() => {
    let apiRequest = new ApiRequest('get', 'info/content/top-banner');
    apiRequest.onSuccess((data) => {
      headerData.value.info = data.content;
    });

    apiRequest.onError((error) => {
      // errors.value = error;
    });

    let data = await apiRequest.request();
    // console.log(data);
  }

  onMounted(() => {
    getBannerContent();
    updateViewport();
    window.addEventListener('resize', updateViewport);

    auth.isAuth(() => {
      auth.extendAuthToken();
    }, () => {
      cookie.authToken.setValue(null);
    });
  });
  onUnmounted(() => {
    window.removeEventListener('resize', updateViewport);
  })
</script>

<style scoped>
  .page{
    padding-top: 4.8rem;
    display: flex;
    flex-direction: column;
  }
  .page:before {
    content: '';
    width: 52.03125%;
    height: 74.074%;
    position: fixed;
    right: 0;
    bottom: 0;
    background: url('~/components/layout/img/decor-gray.svg') right bottom / contain no-repeat;
    z-index: -1;
  }
  .page-layout{
    flex: 1 0 0;
  }
  .page-aside{
    padding-top: 13px;
    padding-bottom: 1.3rem;
  }
  .page-content{
    padding-top: 1.5rem;
    padding-bottom: 1.3rem;
    position: relative;
  }
  .page-content.--error{
    display: flex;
    flex-direction: column;
  }
  .page-aside,
  .page-content{
    height: calc(100vh - 4.8rem);
    overflow-x: hidden;
    overflow-y: auto;
  }

  @media(max-width: 1199px){
    .page-content{
      height: auto;
      overflow: initial;
    }
    .page-content.--error{
      height: auto;
    }
  }
  @media(max-width: 575px){
    .page{
      padding-top: 62px;
    }
    /*
    .page-aside,
    .page-content{
      height: calc(100vh - 62px);
    }
    */
  }
</style>
